import React from 'react';

import { Grid, Typography } from '@mui/material';

import './App.css';
import Login from './components/Login/Login';
import { Footnotes } from './components/Disclaimer/Footnotes';

function App() {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Typography variant="h4" data-testid="impersonate-add-title">
        Impersonate Test User
      </Typography>
      <Login />
      <Footnotes />
    </Grid>
  );
}

export default App;
